<template>
    <div v-show="nowPath!='login' && isShowBar">
        <va-sidebar v-model="enabled" textColor="dark" color="#fff" position="right" class="pt-5 sidebar" :class="{'sidebar_open':enabled}">
            <va-sidebar-item
                v-for="nav in navs"
                :key="nav.to"
                @click="goPage(nav)"
            >
                <va-sidebar-item-content>
                    <va-sidebar-item-title style="height: 24px;">
                        {{ nav.title }}
                    </va-sidebar-item-title>
                </va-sidebar-item-content>
            </va-sidebar-item>
            <va-divider class="mt-5 mb-0"></va-divider>
            <va-sidebar-item @click="logoutHandler">
                <va-sidebar-item-content>
                    <va-sidebar-item-title style="height: 24px;">
                        <span style="color:#df3131 !important;font-weight:500;">Logout</span> 
                    </va-sidebar-item-title>
                </va-sidebar-item-content>
            </va-sidebar-item>
        </va-sidebar>
        <div class="bar_wrap">
            <va-app-bar v-if="breakpoint=='sm' || breakpoint=='xs'" class="bar" color="#fff" textColor="#222" shadow-color="#000">
                <h1 class="titleFont titleFont_sm pa-0">{{webTitle}}</h1>
                <va-spacer />
                <va-icon name="menu" class="mt-1 mr-1" color="#222" @click="enabled=!enabled"/>
            </va-app-bar>
        </div>
        <va-navbar color="#fff" shape v-if="breakpoint!='sm' && breakpoint!='xs'" >
            <template #center >
                <va-navbar-item>
                    <h1 class="titleFont">{{webTitle}}</h1>
                </va-navbar-item>
            </template>
            <template #right >
                <va-navbar-item  v-for="nav in navs" :key="nav.title" class="nav_item" :class="[webTitle == nav.title && 'nav_item_active', (width<1124 && width>768 ) && 'nav_item_md']" @click="goPage(nav)">{{ nav.title=="Tomb Monster"?'Contest':nav.title }}</va-navbar-item>
            </template>
        </va-navbar>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return{
            navs:[
                {title:'NFT Events',path:'/event', component: 'event'},
                {title:'NFT Tickets',path:'/tickets', component: 'tickets'},
            ],
            enabled: false,
            nowPath: '',
        }
    },
    computed:{
        ...mapGetters([
            'lastPeriod',
            'breakpoint',
            'width',
            'errorMsg'
        ]),
        webTitle(){
            let pathName = this.$route.path.split('/')[1]
            this.nowPath = pathName
            let nav = this.navs.find(el=> el.component == pathName)
            if( nav ) return nav.title
        },
        admin(){
            return this.$route.query.admin
        },
        isShowBar(){
            let nav = this.navs.findIndex(el=>el.component == this.nowPath)
            if(nav == -1) return false
            else return true
        },
    },
    watch:{
    },
    mounted(){
    },
    methods:{
        ...mapActions([
            'connect',
            'setGameData',
            'getGameKeyList',
            'logout'
        ]),
        async goPage(nav){
            // console.log({nav});
            this.enabled = false
            // console.log('this.errorMsg.status',this.errorMsg.status);
            if(this.errorMsg.status) return
            if(nav.title == 'Tomb Monster'){
                // console.log(this.contractLottery);
                await this.getGameKeyList()
                this.$router.push(`/${this.lastPeriod}`)
                if(this.webTitle == 'Tomb Monster'){
                    this.$route.params.period = (this.lastPeriod).toString()
                    this.$store.commit('setPeriod',this.$route.params.period)
                    await this.setGameData()
                }
            }else{
                window.scrollTo({top:0, behavior: 'smooth'})
                this.$nextTick(()=>this.$router.push(`${nav.path}`))
            }
        },
        async logoutHandler(){
            this.enabled = false
            this.logout()
        },
    }
}
</script>
<style scoped lang="scss">
@import "../assets/main.scss";
.sidebar{
    position: fixed; 
    z-index:2;
    opacity:0.98;
    &_open{
        width: 100% !important;
    }
    :deep(.va-sidebar__title.va-sidebar-item-title){
        color: #222 !important;
        text-align: center;
    }
}
.bar{
    height: 44px;
    position: fixed;
    top: 0;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 8px;
    &_wrap{
        position: relative;
    }
}
.titleFont{
  font-size: 38px;
  color: #222;
  @include center();
  &_sm{
      font-size: 20px;
      white-space: nowrap;
      padding-bottom: 30px;
  }
}
.nav_item{
    transition: all 0.5s;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    z-index: 1;
    &:hover,&_active{
        color: #0a3432;
    }
    &_md{
        padding-top: 100px;
        &:hover,&_active{
            color: $primaryColor !important;
        }
    }
}
</style>