<template>
    <navbar/>
    <router-view/>
    <modal-error-msg/>
</template>
<script>
import Navbar from  './components/Navbar.vue'
import ModalErrorMsg from  './components/ModalErrorMsg.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components:{
    Navbar,
    ModalErrorMsg,
  },
  async mounted(){
    const path = this.$route.path

    // await this.accountListener()
    this.checkLogin()
    window.addEventListener('resize', this.getDimensions)
    this.getBreakpoint(this.width)
  },
  unmounted(){
      window.removeEventListener('resize', this.getDimensions)
  },
  watch:{
      width(val){
          this.getBreakpoint(val)
      },
      path(val){
        this.checkLogin(val)
      }
  },
  computed:{
      ...mapGetters([
          'contractLottery',
          'width',
          'breakpoint',
      ]),
      path(){
        let pathName = this.$route.path
        return pathName
      }
  },
  methods:{
    ...mapActions([
      'connect',
      'getBreakpoint',
      'accountListener',
      'login',
      'checkLogin'
    ]),
    getDimensions(){
        this.$store.commit('setWidth',innerWidth)
    },
  }
}
</script>
<style lang="scss">
@import "assets/main.scss";
#app {
  letter-spacing: 0.5px;
}
body{
  display:flex; 
  flex-direction:column; 
  height: 100vh;
  // background-color: #05182c;
  // background: url('assets/img/bg.png') center no-repeat;
  background-size: cover;
  @media(max-width:400px) {
    height: 150vh;
  }
}
</style>
