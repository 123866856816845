import { ethers } from "ethers";
import axios from 'axios'
import router from '../../router'

import WalletConnectProvider from "@walletconnect/web3-provider";

const { ethereum } = window;
const host_url = process.env.VUE_APP_HOST_URL

const state = {
    address: '',
    provider: null,
    tenant: 'burger_nft'
}
const getters = {
    address: (state) => state.address,
    provider: (state) => state.provider,
    tenant: (state) => state.tenant,
}
const mutations = {
    setAddress: (state, address) => (state.address = address),
    setProvider: (state, provider) => (state.provider = provider),
    setTenant: (state, tenant) => (state.tenant = tenant),
}
const actions = {
    async accountListener({ dispatch, commit }) {
        // console.log('accountListener');
        let checkE = await dispatch('checkEthereum')
        if (!checkE) return
        ethereum.on('accountsChanged', function (accounts) {
            console.log('account changed!', accounts);
            if (accounts.length != 0) location.reload()
            else {
                dispatch('getAddress')
            }
        });
    },
    async checkEthereum({ commit }) {
        // console.log('checkEthereum');
        if (!ethereum) {
            console.log('no ethereum');
            commit("setErrorMsg", {
                status: true,
                type: "",
                title: "MetaMask not installed",
                content: "You must install MetaMask from Chrome Web Store.",
                critical: true,
            });
            return false;
        } else return true
    },

    async setupProvider({ commit, dispatch }, type) {
        let provider = null;
        if (type == 'WalletConnect') {
            console.log('setupProvider using WalletConnect');
            const wcProvider = new WalletConnectProvider({
                // infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
                rpc: {
                    1: "https://rpc.ftm.tools/"
                }

            });

            //  Enable session (triggers QR Code modal)
            await wcProvider.enable();

            // use WalletConnectProvider
            provider = new ethers.providers.Web3Provider(wcProvider);

        } else {
            // use metamask
            console.log('setupProvider using metamask');

            let checkE = await dispatch('checkEthereum')
            if (!checkE) return

            provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
        }
        commit('setProvider', provider);

        console.log('setProvider: ok');

    },
    async login({ state, commit, dispatch }, type) {
        // 0 checklogin
        try {
            commit('setBtnLoading', true, { root: true })
            // 0 setup Provider
            await dispatch("setupProvider", type);
            // 1 getAddress
            await dispatch('getAddress')
            // 2 getNonceMsg
            let msg = await dispatch('getNounceMsg')
            // 3 sign get password
            let signature = await dispatch('sign', msg)
            // 4 login
            await dispatch('loginApi', signature)
            // 5 set cookies
            dispatch("setCookie", { cname: "NFTtickets_login", cvalue: "1", exHours: 4 })
            // 6 join tenant
            await dispatch('joinTenant')
            // 7 go event page
            commit('setBtnLoading', false, { root: true })
            router.push('/event')
        } catch (err) {
            commit("setErrorMsg", {
                status: true,
                type: "normal",
                title: "Login Fail.",
                content: err.message,
                critical: true,
            });
            commit('setBtnLoading', false, { root: true })
        }
    },
    async checkLogin({ dispatch }, path) {
        console.log({ path });
        let cookie = await dispatch('getCookie', 'NFTtickets_login')
        // console.log({ cookie });
        if (!cookie) {
            router.push('/login')
        } else {
            if (path == '/' || path == '/login' || !path) {
                router.push('/event')
                //tmp
                router.push('/tickets')
            }
        }
    },
    async getAddress({ dispatch, commit }) {
        try {


            console.log(1);


            console.log(2, state.provider);





            let accounts = await state.provider.listAccounts();
            console.log(3, { accounts });

            // console.log({ provider, signer, address });
            commit("setAddress", accounts[0]);

        } catch (err) {
            commit("setErrorMsg", {
                status: true,
                type: "normal",
                title: "",
                content: "Get Address Fail.",
                critical: true,
            });
        }
    },
    async getNounceMsg({ state, commit }) {
        try {
            let res = await axios.get(`${host_url}/web3/nonce?address=${state.address}`)
            console.log({ msg: res.data.message });
            return res.data.message
        } catch (err) {
            commit("setErrorMsg", {
                status: true,
                type: "normal",
                title: "",
                content: "Get NounceMsg Fail.",
                critical: true,
            });
        }
    },
    async sign({ state, commit }, message) {
        try {
            const signer = state.provider.getSigner();
            // const signer = state.provider.getSigner(state.address);
            const signature = await signer.signMessage(message);
            console.log({ signature });
            return signature
        } catch (err) {
            commit("setErrorMsg", {
                status: true,
                type: "normal",
                title: "Sign Fail.",
                content: err.message,
                critical: true,
            });
        }
    },
    async sign_old({ commit }, message) {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const signature = await signer.signMessage(message);
            console.log({ signature });
            return signature
        } catch (err) {
            commit("setErrorMsg", {
                status: true,
                type: "normal",
                title: "Sign Fail.",
                content: err.message,
                critical: true,
            });
        }
    },
    async loginApi({ state, commit }, password) {
        try {
            await axios.post(`${host_url}/web3/login/`, {
                username: state.address,
                password
            })
        } catch (err) {
            commit("setErrorMsg", {
                status: true,
                type: "normal",
                title: "loginApi Fail.",
                content: err.message,
                critical: true,
            });
        }
    },
    setCookie({ }, { cname = "", cvalue = "", exHours = null }) {
        let d = new Date();
        d.setTime(d.getTime() + exHours * 60 * 60 * 1000);
        let expires = `expires=${d.toGMTString()}`;
        document.cookie = `${cname}=${cvalue};${expires}}`;
    },
    getCookie({ commit }, cname) {
        let name = `${cname}=`;
        let ca = document.cookie.split(";");
        let value = "";
        ca.forEach((el) => {
            el = el.trim();
            if (el.indexOf(name) == 0) {
                value = el.substring(name.length, el.length);
            }
        });
        // return value
        // commit("setLoginCookie", value);
        return value
    },
    async joinTenant({ state, commit }) {
        try {
            let res = await axios.post(`${host_url}/tenants/join/?key=${state.tenant}`)
            console.log(res)
        } catch (err) {
            commit('setErrorMsg400', err)
        }
    },
    async logout({ dispatch }) {
        try {
            let res = await axios.post(`${host_url}/web3/logout/`)
            console.log(res)
            await dispatch('setCookie', {
                cname: "NFTtickets_login",
                cvalue: 0,
                exHours: 0
            })
            router.push('/login')
        } catch (err) {
            commit('setErrorMsg400', err)
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}