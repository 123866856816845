import { createStore } from "vuex";
import { ethers } from "ethers";
import auth from "./modules/auth";
import event from "./modules/event";


export default createStore({
  state: {
    width: innerWidth,
    breakpoint: '',
    errorMsg: { status: false, type: "normal", title: "", content: "", critical: false },
    btnLoading: false,
  },
  getters: {
    width: (state) => state.width,
    breakpoint: (state) => state.breakpoint,
    errorMsg: (state) => state.errorMsg,
    btnLoading: (state) => state.btnLoading,
  },
  mutations: {
    setWidth: (state, width) => (state.width = width),
    setBreakpoint: (state, breakpoint) => (state.breakpoint = breakpoint),
    setErrorMsg: (state, errorMsg) => {
      if (state.errorMsg.critical && errorMsg.status) return
      state.errorMsg = errorMsg
    },
    setErrorMsg400: (state, error) => {
      if (state.errorMsg.critical && errorMsg.status) return
      state.errorMsg = {
        status: true,
        type: "normal",
        title: "發生錯誤",
        content: "發生錯誤，請稍候再試。" + error,
        critical: true,
      }
    },
    setBtnLoading: (state, btnLoading) => (state.btnLoading = btnLoading),
  },
  actions: {
    getBreakpoint({ commit }, val) {
      if (val < 576) {
        commit('setBreakpoint', 'xs')
      } else if (val >= 1200) {
        commit('setBreakpoint', 'xl')
      } else if (val >= 992) {
        commit('setBreakpoint', 'lg')
      } else if (val >= 768) {
        commit('setBreakpoint', 'md')
      } else if (val >= 576) {
        commit('setBreakpoint', 'sm')
      }
    },
  },
  modules: {
    auth,
    event,
  },
});
