import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VuesticPlugin } from "vuestic-ui";
import "vuestic-ui/dist/vuestic-ui.css";
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

createApp(App)
  .use(store)
  .use(router)
  .use(VuesticPlugin, {
    colors: {
      // primary: "#4ecdc4",
      // secondary: "#2C82E0",
      // primary: "#e58477",
      primary: "#3b8aff",
      secondary: "#2C82E0",
    },
  })
  .use(VueAxios, axios)
  .mount("#app");
