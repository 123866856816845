<template>
    <div>
        <va-modal v-model="errorMsg.status" hide-default-actions>
            <template #header>
                <div class="row">
                    <div class="flex" style="margin-left:auto;">
                        <va-button icon="close" flat  @click="close" :disabled="disabled" 
                        v-show="errorMsg.type && errorMsg.type != 'normal' && errorMsg.type != 'loading'"
                        ></va-button>
                    </div>
                </div>
                <h2 class="display-6 mb-3 mx-3" style="margin-right:auto;">
                    {{errorMsg.title}}
                </h2>
            </template>
            <slot>
                <!-- normal -->
                <div class="content" v-html="errorMsg.content"></div>
                <!-- register -->
                <div class="content rgst" v-show="errorMsg.type=='register'">
                    您尚有 <span>{{nftAmt}}</span> 張票券可兌換
                </div>
            </slot>
                <div v-show="simpleErr" style="margin:auto;" class="mt-4">
                    <va-alert color="danger">
                    {{simpleErr}}
                    </va-alert>
                </div>
            <template #footer>
                <!--footer-normal -->
                <va-button v-if="errorMsg.type == 'normal'" @click="close('ok')">
                ok
                </va-button>
                <!-- footer register -->
                <div v-if="errorMsg.type == 'register'" class="row justify--center mt-4">
                    <div class="flex">
                        <va-button class="mx-2 px-3" @click="register" :loading="rgstBtnLoading">
                        確定
                        </va-button>
                    </div>
                    <div class="flex">
                        <va-button class="mx-2 px-3" @click="close" outline>
                        取消
                        </va-button>
                    </div>
                </div>
                <!-- footer register Success -->
                <div v-if="errorMsg.type == 'rgstSuccess'" class="row justify--center mt-4">
                    <div class="flex">
                        <va-button class="mx-2 px-3" @click="go('/tickets')
                        " >
                        前往票夾
                        </va-button>
                    </div>
                    <div class="flex">
                        <va-button class="mx-2 px-3" @click="afterRgst" outline>
                        繼續瀏覽
                        </va-button>
                    </div>
                </div>
                <!-- footer exchange -->
                <div v-if="errorMsg.type == 'exchange'" class="row justify--center mt-4">
                    <div class="flex">
                        <va-button class="mx-2 px-3" @click="exchange" :loading="btnLoading">
                        確定
                        </va-button>
                    </div>
                    <div class="flex">
                        <va-button class="mx-2 px-3" @click="close" outline>
                        取消
                        </va-button>
                    </div>
                </div>   
            </template>
        </va-modal>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    data(){
        return{
            disabled: false,
            simpleErr: '',
            rgstBtnLoading: false
        }
    },
    computed:{
        ...mapGetters([
            'errorMsg',
            'width',
            'nftAmt',
            'btnLoading'
        ]),
    },
    mounted(){
    },
    methods:{
        ...mapMutations([
            'setErrorMsg',
            'setBtnLoading',
            'setExchangeModal',
        ]),
        ...mapActions([
            'takeTicket',
            'getNFTremain',
            'reedemTicket',
        ]),
        close(ok){
            if(ok)this.disabled = false
            if(this.disabled) return
            this.setErrorMsg({status:false})
            this.simpleErr = ''
        },
        go(url){
            this.close()
            this.$router.push(url)
        },
        async register(){
            this.rgstBtnLoading = true
            await this.takeTicket()
            this.rgstBtnLoading = false
        },
        afterRgst(){
            this.close()
            this.getNFTremain()
        },
        async exchange(){
            this.setBtnLoading(true)
            let res = await this.reedemTicket()
            this.setBtnLoading(false)
            this.setErrorMsg(false)
            this.setExchangeModal(false)
            if(res){
                this.$vaToast.init({ message: '票券兌換成功', color: 'success' })
            }else{
                this.$vaToast.init({ message: '發生錯誤請稍候再試', color: 'danger' })
            }
        },

    }
}
</script>

<style scoped lang="scss">
@import "../assets/main.scss";
.btnDisabled{
    color: #777;
    pointer-events: none;
}
.content{
    color: #666;
    margin: 0 16px;
    &.rgst{
        margin-top: 32px;
        color: #aaa;
        font-size: 14px;
        span{
            color:  $primaryColor;
        }
    }
}
</style>