import axios from 'axios'

const host_url = process.env.VUE_APP_HOST_URL

const state = {
    exchangeModal: false,
    // exchangeTicket: {
    //     title: "Skyline Film 屋頂電影院 5月台北放映",
    //     start_at: "2022-05-01 00:00",
    //     end_at: "2022-05-20 23:50",
    //     order: "2204041132198946899500",
    //     status: 1,
    // },
    // tmp
    exchangeTicket: {
        "id": 8,
        "status": 1,
        "code": "89a4e691-99fb-4a3e-b174-45ccc0abecd5",
        "created_at": "2022-04-29T02:23:49.931961Z",
        "updated_at": "2022-05-03T08:50:30.605116Z",
        "ticket_info": {
            "id": 1,
            "title": "Diamond Ticket",
            "desc": null,
            "start_at": "2022-04-28T10:29:10.215363Z",
            "end_at": "2022-05-28T10:29:10.215364Z"
        }
    },
    nftAmt: 0,
    ticketsList: [],
}
const getters = {
    exchangeModal: (state) => state.exchangeModal,
    exchangeTicket: (state) => state.exchangeTicket,
    nftAmt: (state) => state.nftAmt,
    ticketsList: (state) => state.ticketsList,
}
const mutations = {
    setExchangeModal: (state, exchangeModal) => {
        state.exchangeModal = exchangeModal
    },
    setExchangeTicket: (state, exchangeTicket) => (state.exchangeTicket = exchangeTicket),
    setNftAmt: (state, nftAmt) => (state.nftAmt = nftAmt),
    setTicketsList: (state, ticketsList) => (state.ticketsList = ticketsList),
}
const actions = {
    async getNFTremain({ commit }) {
        let res = await axios.get(`${host_url}/burger_nft/acts/1/ticket_info/1/remaining`)
        // console.log(res);
        commit('setNftAmt', res.data.amount)
    },
    async takeTicket({ commit }) {
        try {
            let res = await axios.post(`${host_url}/burger_nft/acts/1/ticket_info/1/take/`)
            // console.log(res);
            if (res.status == 200 && res.data.msg == "ok") {
                commit('setErrorMsg', {
                    status: true,
                    type: "rgstSuccess",
                    title: "報名成功",
                    content: "活動報名成功！可前往票夾查看活動及票券。",
                })
            } else {
                commit('setErrorMsg400')
            }
        } catch (err) {
            commit('setErrorMsg400', err)
        }
    },
    async getTicketsList({ commit }) {
        try {
            let res = await axios.get(`${host_url}/burger_nft/tickets/`)
            // console.log(res)
            if (res.status == 200) {
                let data = res.data.results
                commit('setTicketsList', data)
            } else {
                commit('setErrorMsg400')
            }
        } catch (err) {
            commit('setErrorMsg400', err)
        }
    },
    async reedemTicket({ state, commit, dispatch }) {
        try {
            let res = await axios.post(`${host_url}/burger_nft/tickets/${state.exchangeTicket.id}/redeem/`)
            // console.log(res)
            if (res.status == 200 && res.data.msg == "ok") {
                // commit('setExchangeModal', true)
                await dispatch('getTicketsList')
                return true
            } else {
                commit('setErrorMsg400')
                return false
            }
        } catch (err) {
            commit('setErrorMsg400', err)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}